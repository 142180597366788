import { useEffect, useState } from 'react'
import { Box, Button, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { BrandingLogos, Footer, LoadingScreen } from '../../components'
import { Form } from './Form'
import { useTranslation } from 'react-i18next'
import { GET_LOGIN_TYPES } from '../../services/graphql'
import { useLazyQuery } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'
import { GetPortal } from '../Login/getPortalTypes.d'
import useDinamicTranslations from '../../hooks/useDinamicTranslations'
import { handleErrorSentry } from '../../utils'

interface DataRecovery {
  code?: number
  detail?: string
  success?: boolean
  name?: string
}

interface FormCompleteProps {
  status: boolean
  dataRecovery: DataRecovery | null
}

interface DataPortalProps {
  data: GetPortal | null
  loading: boolean
}

export const ForgotPassword = () => {
  const [isDesktop] = useMediaQuery('(min-width: 475px)')
  const [dataPortal, setDataPortal] = useState<DataPortalProps>({
    data: null,
    loading: true,
  })
  const [formComplete, setFormComplete] = useState<FormCompleteProps>({
    status: false,
    dataRecovery: null,
  })
  const { t } = useTranslation()
  useDinamicTranslations(['login', 'recovery', 'transversals'])
  const navigate = useNavigate()
  const { state } = useLocation()
  const refer = state?.refer || document.referrer
  // const refer = 'https://testempresa1000.eclass.com'
  const [getLoginTypes, { loading: loadingLoginTypes, error: errorLoginTypes }] =
    useLazyQuery(GET_LOGIN_TYPES)

  document.title = `${localStorage.portalNameLogin} - Recovery password`
  const handleRedirectHome = () => {
    if (dataPortal.data?.Portal.url) {
      window.location.href = dataPortal.data?.Portal.url
    } else {
      console.error('URL or dataPortal is undefined')
    }
  }

  const getPortal = () => {
    const host = import.meta.env.PROD ? `${refer}api/1.0/portal` : '/api'
    fetch(host, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((response) => {
        const portalData = response.data.getPortal

        document.cookie = `CampusVirtual${portalData.Portal.id}=eclass`
        setDataPortal((prevState) => ({
          ...prevState,
          data: portalData,
          loading: false,
        }))
        getLoginTypes({
          variables: {
            LoginTypeInput: {
              portalId: Number(portalData.Portal.id),
            },
          },
        })
      })
      .catch((err) => {
        console.error('Error fetching data: ', err)
        if (refer) {
          handleErrorSentry(
            err,
            'warning',
            'FortgotPassword',
            'getPortal',
            'Error al consultar getPortal',
            'Error getPortal',
          )
        }
        navigate('/Oops')
      })
  }

  if (errorLoginTypes) {
    handleErrorSentry(
      new Error(`errorLoginTypes: ${errorLoginTypes}`),
      'info',
      'FortgotPassword',
      'getLoginTypes',
      'Error al consultar getLoginTypes Graphql',
      'Error getLoginTypes',
    )
  }

  useEffect(() => {
    if (dataPortal.loading) {
      getPortal()
    }
  }, [])
  if (dataPortal.loading || loadingLoginTypes || !dataPortal.data) return <LoadingScreen />

  return (
    <Flex
      display="flex"
      justifyContent="center"
      alignItems="center"
      justifyItems="center"
      paddingBottom="30px"
    >
      <Box>
        <Box width="100%" mb="30px">
          <BrandingLogos showMessage={false} />
        </Box>
        <Box
          justifyContent="center"
          textAlign="center"
          maxW="589px"
          display="grid"
          gap="16px"
          py="32px"
          px="16px"
        >
          <Text fontFamily="Roboto" fontSize="24px" fontWeight="bold" color="#1A202C">
            {!formComplete.status ? t('login:LogInForgotten') : t('recovery:LogInSentEmail')}
          </Text>
          <Text
            fontFamily="Roboto"
            fontSize="18px"
            lineHeight="31px"
            color="#1A202C"
            whiteSpace="pre-wrap"
          >
            {!formComplete.status ? (
              t('recovery:LogInDetails')
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: t('recovery:LogInEmailNewPassword', {
                    email: formComplete.dataRecovery?.detail,
                  }),
                }}
              />
            )}
          </Text>
        </Box>
        <Box justifyContent="center" display="flex">
          <Box width={isDesktop ? '400px' : '100%'} px="16px">
            {!formComplete.status ? (
              <Form
                setFormComplete={setFormComplete}
                portalId={dataPortal?.data?.Portal?.id || 0}
                url={dataPortal?.data?.Portal?.url || ''}
              />
            ) : (
              <Box display="flex" justifyContent="center">
                <Button
                  fontSize="16px"
                  onClick={handleRedirectHome}
                  colorScheme="blue"
                  size="lg"
                  width="50%"
                  backgroundColor="#0189FF"
                  borderRadius="0.25rem"
                  mt={4}
                  _hover={{ backgroundColor: '#017BE5' }}
                  _loading={{
                    backgroundColor: '#0189FF',
                    opacity: '1',
                    color: 'white',
                    cursor: 'wait',
                  }}
                >
                  {t('recovery:LogInBackLogIn')}
                </Button>
              </Box>
            )}
            <Footer showInfo={false} />
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}
