import { useState, ChangeEvent, FC } from 'react'
import { PASSWORD_RECOVERY } from '../../../services/graphql'
import { SelectLogin, InputLogin, Recaptcha, AlertWarning } from '../../../components'
import { useMutation } from '@apollo/client'
import { Box, Button, FormControl, useMediaQuery } from '@chakra-ui/react'
import { LoaderAsync } from '../../../assets'
import { useTranslation } from 'react-i18next'
// import { useParamsLocal } from '../../../context/useParams'

/*
 * Form component
 * Este componente representa el formulario de logueo
 * Valida los inputs y envia la peticion al servidor
 * Redirecciona al usuario en caso de exito
 */

interface FormProps {
  setFormComplete: (value: { status: boolean; dataRecovery: any }) => void
  portalId?: any
  url?: string
}

export const Form: FC<FormProps> = ({ setFormComplete, portalId, url }) => {
  const [isDesktop] = useMediaQuery('(min-width: 475px)')
  const [selectedOption, setSelectOption] = useState<string>('')
  const [documentNumber, setDocumentNumber] = useState<string>('')
  const [loadingError, setLoadingError] = useState({
    status: false,
    message: '',
  })

  const [captcha, setCaptcha] = useState({ verified: false, captchaToken: '' })

  const [addRecovery, { loading }] = useMutation(PASSWORD_RECOVERY)

  const { t } = useTranslation()

  // const { portalId } = useParamsLocal()

  const handleSelectTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectOption(e.target.value)
  }

  // Capture the value of the Input field
  const handleDocumentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDocumentNumber(e.target.value)
  }

  // Submit the form
  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const response = await addRecovery({
        variables: {
          LoginPasswordRecoveryInput: {
            portalId: Number(portalId),
            type: selectedOption,
            username: documentNumber,
            captchaToken: captcha.captchaToken,
          },
        },
      })
      if (!response.data.passwordRecovery.status.success) {
        setLoadingError({
          status: true,
          message: response.data.passwordRecovery.status.detail,
        })
      }
      if (response.data.passwordRecovery.status.success) {
        setFormComplete({
          status: true,
          dataRecovery: response.data.passwordRecovery.status,
        })
      }
    } catch (error) {
      console.log('Error during login', error)
    }
  }

  const optionTextInput = () => {
    if (selectedOption === '') {
      return t('login:LogInDocument')
    } else {
      return selectedOption
    }
  }

  const optionPlaceholderInput = () => {
    if (selectedOption === '') {
      return t('login:LogInUser')
    } else if (selectedOption === 'Email') {
      return t('login:LogInExample')
    } else {
      return selectedOption
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box
        id="form"
        mx={isDesktop ? 8 : 2}
        mt={2}
        sx={{
          ' > *': { marginBottom: '30px' },
          ' * > span': { visibility: 'hidden' },
        }}
      >
        {loadingError.status && <AlertWarning detail={loadingError.message} />}
        <FormControl id="document-type" isRequired>
          <SelectLogin handleSelectChange={handleSelectTypeChange} portalId={portalId} />
        </FormControl>
        <FormControl id="document-number" isRequired>
          <InputLogin
            text={optionTextInput()}
            placeholder={optionPlaceholderInput()}
            isDisabled={selectedOption ? false : true}
            handleInputChange={handleDocumentChange}
            describedby="document-number-helper"
          />
        </FormControl>

        <FormControl id="recaptcha" isRequired>
          <Recaptcha
            verify={() => {}}
            setCaptchaToken={(token: string) => {
              setCaptcha({ verified: Boolean(token), captchaToken: token })
            }}
          />
        </FormControl>

        <Box display="flex" gap="24px">
          <Button
            fontSize="16px"
            type="submit"
            colorScheme="blue"
            isDisabled={!captcha.verified}
            size="lg"
            width="50%"
            backgroundColor="#0189FF"
            borderRadius="0.25rem"
            mt={4}
            _hover={{ backgroundColor: '#017BE5' }}
            isLoading={loading}
            _loading={{
              backgroundColor: '#0189FF',
              opacity: '1',
              color: 'white',
              cursor: 'wait',
            }}
            spinner={<LoaderAsync />}
          >
            {t('recovery:LogInSubmit')}
          </Button>
          <Button
            fontSize="16px"
            colorScheme="blue"
            size="lg"
            width="50%"
            backgroundColor="#E0EEFA"
            textColor="#017BE5"
            borderRadius="0.25rem"
            mt={4}
            onClick={() => (window.location.href = `${url}`)}
            _hover={{ backgroundColor: '#C7DEF2' }}
          >
            {t('recovery:LogInCancel')}
          </Button>
        </Box>
      </Box>
    </form>
  )
}
