import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// Hook para obtener las traducciones dinámicamente

const useDynamicTranslation = (namespaces: string[]) => {
  const { i18n } = useTranslation()
  const location = useLocation()
  useEffect(() => {
    const path = location.pathname.split('/')[1]
    const defaultNamespace = path === '' ? 'common' : path
    // Combina el namespace derivado de la ruta con los que se pasaron como parámetro
    const namespacesToLoad =
      Array.isArray(namespaces) && namespaces.length > 0
        ? [defaultNamespace, ...namespaces]
        : [defaultNamespace]
    namespacesToLoad.forEach((ns) => {
      if (!i18n.hasResourceBundle(i18n.language, ns)) {
        i18n.loadNamespaces(ns)
      }
    })
  }, [location.pathname, i18n, namespaces])
}
export default useDynamicTranslation
