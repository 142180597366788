import { createContext, useContext, useState, useEffect } from 'react'
/*
 * Logica que recupera los parametros de la URL y los guarda en el localStorage
 * para usarse de manera global en la aplicacion
 */

interface ParamsProps {
  system: string | null | undefined
  portalId: number | null | undefined
  redirectUrl: string | null | undefined
}

const Paramscontext = createContext<ParamsProps | undefined>(undefined)

export const ParamsProvider = ({ children }) => {
  const [params, setParams] = useState<ParamsProps>({
    system: localStorage.getItem('system'),
    portalId: Number(localStorage.getItem('portalId')),
    redirectUrl: localStorage.getItem('redirectUrl'),
  })

  useEffect(() => {
    setParams({
      system: localStorage.getItem('system'),
      portalId: Number(localStorage.getItem('portalId')),
      redirectUrl: localStorage.getItem('redirectUrl'),
    })
  }, [])

  return <Paramscontext.Provider value={params}>{children}</Paramscontext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export const useParamsLocal = (): ParamsProps => {
  const context = useContext(Paramscontext)
  if (context === undefined) {
    throw new Error('useParams must be used within a ParamsProvider')
  }
  return context
}
