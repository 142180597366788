import { FC, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  Text,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  useMediaQuery,
  Box,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

/*
 * Notification component
 * Este componente representa el modal de notificaciones
 * Que se ve cuando carga el login
 */

interface NotificationProps {
  ProgramNotices: [
    {
      description: string
      subject: string
    },
  ]
}

export const Notification: FC<NotificationProps> = ({ ProgramNotices }) => {
  const { t } = useTranslation('login')
  const [isMobile] = useMediaQuery('(min-width: 432px)')
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (ProgramNotices && !isOpen) onOpen()
  }, [])
  if (!ProgramNotices) return null
  const isImportant = ProgramNotices.length > 1

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? 'xl' : 'full'}>
      <ModalOverlay />
      <ModalContent borderRadius="8px">
        <ModalHeader
          borderRadius="8px 8px 0px 0px"
          bgColor="#0189FF"
          textColor="white"
          textAlign="center"
          alignContent="center"
          className="custom-modal-header"
          height="72px"
          flex="none"
        >
          <Text fontSize="20px" fontWeight="700" fontFamily="Roboto">
            {isImportant ? t('LogInModalTitle') : ProgramNotices[0].subject}
          </Text>
        </ModalHeader>
        <ModalCloseButton color="white" size="16px" mt="8px" mr="8px" />
        <ModalBody sx={{ whiteSpace: 'pre-line' }}>
          {isImportant &&
            ProgramNotices.map((notice) => (
              <Box key={notice.subject}>
                <Text fontSize="16px" fontWeight="600" fontFamily="Roboto">
                  {notice.subject}
                </Text>
                <Text fontSize="16px" fontWeight="400" fontFamily="Roboto" mb={4} lineHeight="28px">
                  {notice.description}
                </Text>
              </Box>
            ))}
          {!isImportant && (
            <Text fontSize="16px" fontWeight="400" fontFamily="Roboto" lineHeight="28px">
              {ProgramNotices[0].description}
            </Text>
          )}
        </ModalBody>
        <ModalFooter justifyContent="left">
          <Button
            colorScheme="blue"
            bgColor="#0189FF"
            mr={3}
            onClick={onClose}
            fontSize={14}
            fontFamily="Roboto"
            fontWeight="500"
            lineHeight="21px"
            width={isMobile ? '144px' : '100%'}
            _hover={{ backgroundColor: '#017BE5' }}
          >
            {t('LogInModalMassNoticeButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
