import { gql } from '@apollo/client'

export const GET_CHECK_PSWD_CODE = gql`
  query checkPasswordCode($LoginCheckPasswordCodeInput: LoginCheckPasswordCodeInput!) {
    checkPasswordCode(input: $LoginCheckPasswordCodeInput) {
      status {
        success
        detail
        code
        name
      }
    }
  }
`
