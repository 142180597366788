import { Flex, Text } from '@chakra-ui/react'
import { LoadingAnimation } from '../../assets'
import { useTranslation } from 'react-i18next'

/*
 * LoadingScreen component
 * Este componente representa la pantalla de carga
 * Muestra un spinner y un texto de carga
 */

export const LoadingScreen = () => {
  const { t } = useTranslation('transversals')
  return (
    <Flex direction="column" align="center" justify="center" w="100%" h="100vh" bg="white">
      <LoadingAnimation size={16} />
      <Text
        mt="16px"
        fontFamily="Roboto"
        fontSize="18px"
        fontWeight="400"
        textAlign="center"
        color="#999"
      >
        {t('CrossLoading')}
      </Text>
    </Flex>
  )
}
