import { gql } from '@apollo/client'

export const GET_LOGIN_TYPES = gql`
  query getLoginTypes($LoginTypeInput: LoginTypeInput) {
    getLoginTypes(input: $LoginTypeInput) {
      LoginTypes {
        id
        name
      }
      status {
        success
        detail
        code
        name
      }
    }
  }
`
