import * as Sentry from '@sentry/react'

//Componente para manejar errores y enviarlos a Sentry
//Se puede personalizar el nombre del error y el nivel de error

export const handleErrorSentry = (
  err: Error,
  level: 'fatal' | 'error' | 'warning' | 'info' | 'debug' = 'info',
  page?: string,
  action?: string,
  additionalInfo?: string,
  customName?: string,
) => {
  if (import.meta.env.PROD) {
    // Verifica si el error es un SyntaxError y personaliza el mensaje
    if (err instanceof SyntaxError) {
      const customError = new Error(customName)

      // Añade información adicional al nuevo error
      customError.stack = err.stack // Mantén el stack trace original si es necesario

      // Captura el error con información adicional
      Sentry.captureException(customError, {
        level: level,
        tags: { page: page, action: action, url: window.location.href },
        extra: { data: err, additionalInfo: additionalInfo },
      })
    } else {
      // Captura otros tipos de errores normalmente
      Sentry.captureException(err, {
        level: level,
        tags: { page: page, action: action, url: window.location.href },
        extra: { data: err, additionalInfo: additionalInfo },
      })
    }
  }
}
