import { ChangePassword, ForgotPassword, Login, Oops, PageInvalid } from '../View'
import { Routes, Route } from 'react-router-dom'

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/recovery-password" element={<ForgotPassword />} />
      <Route path="/change-password/:token" element={<ChangePassword />} />
      <Route path="/page-no-found/" element={<PageInvalid />} />
      <Route path="/Oops" element={<Oops />} />
      <Route path="*" element={<Oops />} />
    </Routes>
  )
}
