import { gql } from '@apollo/client'

export const LOGIN_MUTATION = gql`
  mutation UserLogin($LoginInput: LoginInput!) {
    UserLogin(input: $LoginInput) {
      token
      entryId
      status {
        code
        name
        detail
        success
      }
    }
  }
`
