import { Flex, Box, Image, Text, useMediaQuery } from '@chakra-ui/react'
import { oopsDesktop, oopsMobile } from '../../assets'
import { Footer } from '../../components'
import { useTranslation } from 'react-i18next'

export const Oops = () => {
  const [isDesktop] = useMediaQuery('(min-width: 768px)')
  document.title = localStorage.portalNameLogin ?? 'No existe' + ' - Oops!'
  const { t } = useTranslation('oops')
  return (
    <Flex minH="100vh" padding={4}>
      <Flex
        w="full"
        alignItems={isDesktop ? 'center' : 'initial'}
        display="flex"
        justifyContent={isDesktop ? 'space-between' : 'center'}
      >
        <Box paddingLeft={isDesktop ? 4 : 0} textAlign={isDesktop ? 'initial' : 'center'}>
          <Text
            fontFamily="Roboto"
            fontSize={isDesktop ? '80px' : '36px'}
            lineHeight={isDesktop ? '94px' : '42px'}
            color="#2F2F2F"
            fontWeight="700"
            paddingBottom={6}
            marginTop={isDesktop ? 'initial' : '40px'}
          >
            Oops!
          </Text>
          {!isDesktop && (
            <Flex justifyContent="center" paddingBottom={6}>
              <Image src={oopsMobile} maxW="185px" alt="oops" />
            </Flex>
          )}
          <Text
            fontFamily="Roboto"
            fontSize="16px"
            lineHeight="21px"
            color="#808080"
            fontWeight="400"
          >
            {t('LogOopsDescribe')}
          </Text>
          <Flex justifyContent={isDesktop ? 'initial' : 'center'}>
            <Footer showInfo={false} />
          </Flex>
        </Box>
      </Flex>
      {isDesktop && (
        <Box w="full">
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Image src={oopsDesktop} maxW="542px" w="100%" alt="oops"/>
          </Flex>
        </Box>
      )}
    </Flex>
  )
}
