import { useState, ChangeEvent } from 'react'
import { CHANGE_PSW_MUTATION } from '../../../services/graphql'
import { InputLogin, Recaptcha, AlertWarning, ProgressPasswordStrength } from '../../../components'
import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  useMediaQuery,
  Icon,
  Text,
} from '@chakra-ui/react'
import { Error as IconError, LoaderAsync } from '../../../assets'
import { useTranslation } from 'react-i18next'
import { encryptData } from '../../../utils'
import { useParams } from 'react-router-dom'
import { passwordStrength } from 'check-password-strength'

/*
 * Form component
 * Este componente representa el formulario de logueo
 * Valida los inputs y envia la peticion al servidor
 * Redirecciona al usuario en caso de exito
 */

interface LoadingErrorProps {
  status: boolean
  message: string
  typeStatus?: 'warning' | 'info' | 'success' | 'error' | 'loading'
}

export const Form = ({ portalId, url }) => {
  const [isDesktop] = useMediaQuery('(min-width: 475px)')
  const [password, setPassword] = useState<string>('')
  const [passwordAgain, setPasswordAgain] = useState<string>('')
  const [isError, setIsError] = useState(false)
  const [loadingError, setLoadingError] = useState<LoadingErrorProps>({
    status: false,
    message: '',
    typeStatus: 'warning',
  })

  const [captcha, setCaptcha] = useState({ verified: false, captchaToken: '' })

  const [addChangePSW, { loading }] = useMutation(CHANGE_PSW_MUTATION)

  const { t } = useTranslation()

  const { token } = useParams()

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setPassword(value)
    setIsError(value !== passwordAgain)
  }

  // Capture the value of the Input field
  const handlePasswordChangeAgain = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordAgain(e.target.value)
    setIsError(e.target.value !== password)
  }

  // Submit the form
  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password !== passwordAgain) {
      setIsError(true)
      return
    }
    setLoadingError({
      status: true,
      message: t('changepwd:LogInProcessing'),
      typeStatus: 'info',
    })
    try {
      const response = await addChangePSW({
        variables: {
          UserChangePasswdInput: {
            code: token,
            passwd: await encryptData(password),
            origin: 'campusvirtual',
            portalId: Number(portalId),
            forceSecure: true,
          },
        },
      })
      if (!response.data.UserChangePasswd.status.success) {
        setLoadingError({
          status: true,
          message: response.data.UserChangePasswd.status.detail,
          typeStatus: 'warning',
        })
      }
      if (response.data.UserChangePasswd.status.success) {
        setLoadingError({
          status: true,
          message: t('changepwd:LogInUpdated'),
          typeStatus: 'success',
        })
        setTimeout(() => {
          window.location.href = response.data
          window.location.href = url
        }, 2500)
      }
    } catch (error) {
      console.log('Error during login', error)
    }
  }

  const disableSendButton = !captcha.verified || isError || !(passwordStrength(password).id >= 2)

  return (
    <form onSubmit={handleSubmit}>
      <Box
        id="form"
        mx={isDesktop ? 8 : 2}
        mt={2}
        sx={{
          ' > *': { marginBottom: '30px' },
          ' * > span': { visibility: 'hidden' },
          input: { borderWidth: '0.5px' },
        }}
      >
        {loadingError.status && (
          <AlertWarning detail={loadingError.message} status={loadingError.typeStatus} />
        )}
        <FormControl id="password" isRequired>
          <InputLogin
            text={t('changepwd:LoginChangePassword')}
            type="password"
            placeholder={t('changepwd:LogInEnterNewPass')}
            handleInputChange={handlePasswordChange}
            describedby="password-helper"
          />
          <ProgressPasswordStrength password={password} />
        </FormControl>
        <FormControl id="repeat-password" isRequired isInvalid={isError}>
          <InputLogin
            text={t('changepwd:LogInRepeatPass')}
            type="password"
            placeholder={t('changepwd:LogInRepeatNewPass')}
            handleInputChange={handlePasswordChangeAgain}
            describedby="password-helper"
            isDisabled={password.length === 0}
          />
          {isError && (
            <FormErrorMessage>
              <Icon as={IconError} />
              <Text marginLeft={2} fontFamily="Roboto" fontSize={12}>
                {t('changepwd:LogPasswordNoMatch')}
              </Text>
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="recaptcha" isRequired>
          <Recaptcha
            verify={() => {}}
            setCaptchaToken={(token: string) => {
              setCaptcha({ verified: Boolean(token), captchaToken: token })
            }}
          />
        </FormControl>

        <Box display="flex" gap="24px">
          <Button
            fontSize="16px"
            type="submit"
            colorScheme="blue"
            isDisabled={disableSendButton}
            size="lg"
            width="50%"
            backgroundColor="#0189FF"
            borderRadius="0.25rem"
            mt={4}
            _hover={{ backgroundColor: '#017BE5' }}
            isLoading={loading}
            _loading={{
              backgroundColor: '#0189FF',
              opacity: '1',
              color: 'white',
              cursor: 'wait',
            }}
            spinner={<LoaderAsync />}
          >
            {t('recovery:LogInSubmit')}
          </Button>
          <Button
            fontSize="16px"
            colorScheme="blue"
            size="lg"
            width="50%"
            backgroundColor="#E0EEFA"
            textColor="#017BE5"
            borderRadius="0.25rem"
            mt={4}
            onClick={() => (window.location.href = url)}
            _hover={{ backgroundColor: '#C7DEF2' }}
          >
            {t('recovery:LogInCancel')}
          </Button>
        </Box>
      </Box>
    </form>
  )
}
