import ZendeskReact from 'react-zendesk'

import { useLoadZendesk } from './useLoadZendesk'

export const Zendesk = () => {
  const { integrations, load } = useLoadZendesk()

  const zendeskKey = 'd6516c64-627d-44b5-8563-b025bcb3b2a1'
  if (
    !zendeskKey ||
    zendeskKey.length === 0 ||
    !integrations ||
    (integrations && integrations.zendesk === null)
  ) {
    return <></>
  }
  return <>{!load && integrations && <ZendeskReact zendeskKey={integrations.zendesk} />}</>
}
