import { useParamsLocal } from '../../context/useParams'
import { useEffect, useState } from 'react'

/**
 * @returns {object} integrations
 * @returns {boolean} load
 * @example
 * const { integrations, load } = useLoadZendesk()
 *
 * Este hook se encarga de cargar las integraciones de la plataforma
 * leyendo el localStorage, si no existen las carga desde el backend
 */

interface UseLoadZendeskProps {
  integrations: any
  load: boolean
}

export const useLoadZendesk = (): UseLoadZendeskProps => {
  const [integrations, setIntegrations] = useState(null)
  const [load, setLoad] = useState(true)

  const { portalId } = useParamsLocal()

  useEffect(() => {
    if (timeIntegrations()) {
      getIntegration()
    } else {
      setLoad(false)
      setIntegrations(JSON.parse(localStorage.getItem('integrations') || '""'))
    }
  }, [])

  const getIntegration = async () => {
    const refer = document.referrer
    // const host = 'https://campus.eclass.com/api/1.0/portal/integrations'
    const host = `${refer}api/1.0/portal/integrations`
    await fetch(host, {
      method: 'GET',
      headers: {
        origin: refer,
        // 'X-Portal-ID': `${portalId}`,
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((res) => {
        setIntegrations(res.data)
        setLoad(false)
        localStorage.setItem(
          'integrations',
          res.data === undefined ? 'null' : JSON.stringify(res.data),
        )
      })
  }
  return { integrations, load }
}
/* esta funcion se encarga de validar si ya pasaron 60 minutos desde la ultima vez que se cargaron las integraciones */

function timeIntegrations() {
  const timeIntegrations = localStorage.getItem('timeIntegrations')
  if (timeIntegrations === null) {
    localStorage.setItem('timeIntegrations', new Date().getTime().toString())
    return true
  } else {
    const time = new Date().getTime()
    const timeDiff = time - Number(timeIntegrations)
    const minutes = Math.floor(timeDiff / 60000)
    if (minutes >= 60) {
      localStorage.removeItem('integrations')
      localStorage.removeItem('timeIntegrations')
    }
    return false
  }
}
