/**
 * Function to shade a color by a given percentage.
 * @param color - The original color in hexadecimal format.
 * @param percent - The percentage to shade the color. Negative values darken the color, positive values lighten it.
 * @returns The shaded color in hexadecimal format.
 */
export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = Math.min(255, Math.max(0, Math.floor((R * (100 + percent)) / 100)))
  G = Math.min(255, Math.max(0, Math.floor((G * (100 + percent)) / 100)))
  B = Math.min(255, Math.max(0, Math.floor((B * (100 + percent)) / 100)))

  const RR = (R.toString(16).length === 1 ? '0' : '') + R.toString(16)
  const GG = (G.toString(16).length === 1 ? '0' : '') + G.toString(16)
  const BB = (B.toString(16).length === 1 ? '0' : '') + B.toString(16)

  return `#${RR}${GG}${BB}`
}
