import { gql } from '@apollo/client'

export const GET_PORTAL_FOOTER = gql`
  query getPortalFooter($PortalFooterInput: PortalFooterInput) {
    getPortalFooter(input: $PortalFooterInput) {
      Links {
        title
        url
        target
      }
      status {
        success
        detail
        code
        name
      }
    }
  }
`
