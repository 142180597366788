import { gql } from '@apollo/client'

export const PASSWORD_RECOVERY = gql`
  mutation passwordRecovery($LoginPasswordRecoveryInput: LoginPasswordRecoveryInput!) {
    passwordRecovery(input: $LoginPasswordRecoveryInput) {
      status {
        success
        detail
        code
        name
      }
    }
  }
`
