import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import client from './apollo/apolloClient'
import { ApolloProvider } from '@apollo/client'
//...
import * as Sentry from '@sentry/react'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://ee85cdcb4d1a82cd0020cb4aa9ce2569@o164244.ingest.us.sentry.io/4507815871578112',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      /** Error ignorado por recomendación del desarrollador. */
      'ResizeObserver',
      /** En la práctica no es un error válido ya que al desmontarse un componente se llama a la referencia de otro componente antes que se desmonte y se haga el redirect con history.push(...) */
      'ChunkLoadError',
      /** Errores ignorados ya que son de respuesta del servidor que deben ser optimizados.  */
      'Network Error',
      'AbortError',
    ],
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_APP_VERSION,
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
)
