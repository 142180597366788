import { Box, Text } from '@chakra-ui/react'
import ReCAPTCHA from 'react-google-recaptcha'

// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const Recaptcha = ({ verify, setCaptchaToken }: any) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Text fontFamily="Roboto" pb={4}>
        {t('LogInNotRobot')}
      </Text>
      <ReCAPTCHA
        sitekey="6LffuakUAAAAAD6DpHSbw5bxOlfHf714Daz-8X2m"
        onChange={(token: string) => {
          setCaptchaToken(token)
          verify(true)
        }}
      />
    </Box>
  )
}
