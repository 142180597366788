import { useEffect, useState } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Zendesk } from './components'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n/i18n'
import { ParamsProvider } from './context/useParams'
import { BrowserRouter } from 'react-router-dom'
import { PublicRoutes } from './routes'
import { LoadingScreen } from './components/Loading/LoadingScreen'

export const LAST_CLEANUP_KEY = 'horaUltimaLimpieza'
export const FOUR_HOURS = 4 * 60 * 60 * 1000

function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Setea la data inicial del login
    const setInitialData = () => {
      const urlParams = new URLSearchParams(window.location.search)
      const system = urlParams.get('system')
      const portalId = urlParams.get('portal_id')

      if (system) localStorage.setItem('system', system)
      if (portalId) localStorage.setItem('portalId', portalId)

      setLoading(false)
    }

    // Funcion que limpia el localstorage del login cada 4 horas
    const now = new Date().getTime()
    const lastCleanup = localStorage.getItem(LAST_CLEANUP_KEY)
    if (!lastCleanup || now - parseInt(lastCleanup) > FOUR_HOURS) {
      localStorage.clear()
      localStorage.setItem(LAST_CLEANUP_KEY, now.toString())
    }
    setInitialData()
  }, [])

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <I18nextProvider i18n={i18n}>
      <ParamsProvider>
        <ChakraProvider>
          <BrowserRouter>
            <PublicRoutes />
          </BrowserRouter>
        </ChakraProvider>
        <Zendesk />
      </ParamsProvider>
    </I18nextProvider>
  )
}

export default App
