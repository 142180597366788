import { Box, useMediaQuery, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Types from './BrandingLogos.types'

// Debe recibir por lo menos 1 de los dos, array de logo o un string de logo por defecto
export const BrandingLogos = ({ showMessage = true, text }: Types.BrandingLogos) => {
  const { t } = useTranslation('login')
  const [isDesktop] = useMediaQuery('(min-width: 932px)')
  const defaultLogo = 'https://cdn-platform.eclass.com/static/media/eclass.5ed089e9.svg'
  const logos = localStorage.getItem('logo') ? JSON.parse(localStorage.getItem('logo')!) : null

  if (logos?.length === 0 && !defaultLogo) {
    return null
  }

  // En espera para agregar analytics
  const analytics = () => {}
  return (
    <Box bgColor={showMessage ? (isDesktop ? 'transparent' : '#F8F8F8') : 'transparent'}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        data-testid="brandingLogos"
        onClick={analytics}
        gap="16px"
        paddingTop={logos && logos.length > 0 ? '40px' : 0}
      >
        {logos && logos.length > 0 ? (
          logos.map((logo, index) => (
            <Box w="100px" h="56px" key={logo.name + index}>
              <figure>
                <img src={logo.url} alt={logo.name} />
              </figure>
            </Box>
          ))
        ) : (
          <Box w="186px" h="103px">
            <figure>
              <img src={defaultLogo} alt="Logo" />
            </figure>
          </Box>
        )}
      </Box>
      {!isDesktop && showMessage && (
        <Text textAlign="center" py={5} mx="50px" fontFamily="Roboto" fontSize="1.125rem">
          {text ?? t('LogInStart')}
        </Text>
      )}
    </Box>
  )
}
