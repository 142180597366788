import { Icon } from '@chakra-ui/react'

export const AcordionClosed = (props) => {
  return (
    <Icon viewBox="0 0 17 9" width="17" height="9" {...props}>
      <path
        fill="#999"
        fillRule="evenodd"
        d="M8.666 8.5a.993.993 0 0 1-.65-.241L.278 1.609 1.577.09l7.089 6.093L15.755.09l1.298 1.519-7.738 6.65a.993.993 0 0 1-.65.241z"
      />
    </Icon>
  )
}
