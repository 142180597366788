import { gql } from '@apollo/client'

export const GET_PROGRAM_NOTICES = gql`
  query ProgramNotices($ProgramNoticeInput: ProgramNoticeInput) {
    ProgramNotices(input: $ProgramNoticeInput) {
      ProgramNotices {
        subject
        description
      }
      status {
        success
        code
      }
    }
  }
`
