import { Icon } from '@chakra-ui/react'

export const Error = () => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      focusable="false"
      aria-hidden="true"
      aria-label=""
    >
      <title>icon-iconError</title>
      <g clipPath="url(#clip0_2165_6683)">
        <circle cx="6" cy="6" r="6" fill="#CC0000"></circle>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.87867 2.46447L2.46446 3.87868L4.58578 6L2.46446 8.12132L3.87867 9.53553L5.99999 7.41421L8.12131 9.53553L9.53553 8.12132L7.41421 6L9.53553 3.87868L8.12131 2.46447L5.99999 4.58579L3.87867 2.46447Z"
          fill="white"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2165_6683">
          <rect width="12px" height="12px" fill="white"></rect>
        </clipPath>
      </defs>
    </Icon>
  )
}
