const config = {
  secret_key: import.meta.env.VITE_ECRYPTO_SECRET_KEY || 'superinsegurekey',
  secret_iv: import.meta.env.VITE_ECRYPTO_SECRET_IV || 'ultrasuperinsecuresecretiv123418',
  encryption_method: 'AES-CBC',
}

// Creates a hash from the data using SHA-512 simulate crypto
async function createHash(data) {
  const encoder = new TextEncoder()
  const dataBuffer = encoder.encode(data)
  const hashBuffer = await crypto.subtle.digest('SHA-512', dataBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}

// Encrypts the data using the secret key and iv
export async function encryptData(data) {
  const secretKeyHash = await createHash(config.secret_key)
  const ivHash = await createHash(config.secret_iv)

  const key = secretKeyHash.substring(0, 32)
  const iv = ivHash.substring(0, 16)

  const encoder = new TextEncoder()
  const dataBuffer = encoder.encode(data)

  const algorithm = {
    name: 'AES-CBC',
    iv: new TextEncoder().encode(iv),
  }

  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(key),
    algorithm,
    false,
    ['encrypt'],
  )

  const encryptedBuffer = await crypto.subtle.encrypt(algorithm, cryptoKey, dataBuffer)
  const encryptedArray = Array.from(new Uint8Array(encryptedBuffer))
  const encryptedHex = encryptedArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  // Converts the hex to base64
  return btoa(encryptedHex)
}
