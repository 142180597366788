import { FC } from 'react'
import { Box, Button, useMediaQuery, Divider, Text } from '@chakra-ui/react'
import { shadeColor } from '../../../utils'
import { PortalSso } from '../getPortalTypes.d'
import { useTranslation } from 'react-i18next'

/*
 * Sso component
 * Este componente representa los botones de acceso rapido a los portales de SSO
 */

interface SsoProps {
  itemsSso: PortalSso[] | null | undefined
  campusId: string
  showMessage?: boolean
}

export const Sso: FC<SsoProps> = ({ itemsSso, campusId, showMessage }) => {
  const [isDesktop] = useMediaQuery('(min-width: 932px)')
  const { t } = useTranslation('login')

  if (itemsSso === null || itemsSso === undefined) return null

  const redirectUrl = ({ id, portalId }) => {
    const urlSso = `https://spcv.eclass.com/sso/spinitsso-redirect-cv?id=${id}&portal=${portalId}&campus=${campusId}`
    window.location.href = urlSso
  }
  return (
    <Box
      id="form"
      mx={isDesktop ? 12 : 2}
      sx={{
        ' > *': { marginBottom: '30px' },
        ' * > span': { visibility: 'hidden' },
      }}
    >
      {itemsSso.map(
        (item) =>
          item.visible && (
            <Button
              key={item.id}
              colorScheme="blue"
              fontFamily="Roboto"
              size="lg"
              width="100%"
              backgroundColor={item.color ?? '#0075B0'}
              borderRadius="0.25rem"
              fontWeight="400"
              fontSize={16}
              mb={6}
              _hover={{
                backgroundColor: shadeColor(item.color ?? '#0075B0', -10),
              }}
              onClick={() => redirectUrl({ ...item })}
            >
              {item.text}
            </Button>
          ),
      )}
      <Divider color="#e6e6e6" opacity="1" />
      {showMessage && (
        <Text fontSize="sm" color="#808080" textAlign="center" mt={4} fontFamily="Roboto">
          {t('LogInSsoText')}
        </Text>
      )}
    </Box>
  )
}
