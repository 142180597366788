// import React, {useState} from "react";
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { passwordStrength } from 'check-password-strength'

export const ProgressPasswordStrength = ({ password }) => {
  const { t } = useTranslation('changepwd')

  const getTranslation = (key) => {
    switch (key) {
      case 0:
        return ''
      case 1:
        return t('LogPasswordVeryWeak')
      case 2:
        return t('LogPasswordWeak')
      case 3:
        return t('LogPasswordStrong')
      case 4:
        return t('LogPasswordVeryStrong')
      default:
        return ''
    }
  }

  // Definir colores según el puntaje
  const getColor = (score) => {
    switch (score) {
      case 0:
        return '#CC0000'
      case 1:
        return '#FF7A14'
      case 2:
        return '#F5A623'
      case 3:
        return '#28A745'
      default:
        return '#C6C6C6'
    }
  }
  return (
    <Box textAlign="end">
      <Flex height="4px" mt={2} overflow="hidden">
        {[...Array(4)].map((_, i) => (
          <Box
            key={i}
            flex="1"
            bg={
              password.length > 0 && i - 1 < passwordStrength(password).id
                ? getColor(i)
                : 'gray.300'
            }
            transition="background-color 0.3s"
            marginRight={i < 3 ? 1 : 0}
          />
        ))}
      </Flex>
      <Text
        fontSize="14px"
        fontFamily="Roboto"
        fontStyle="normal"
        lineHeight="18px"
        color="#999999"
        mt={1}
      >
        {password.length > 0 && getTranslation(passwordStrength(password).id + 1)}
      </Text>
    </Box>
  )
}
