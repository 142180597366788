import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const isProduction = import.meta.env.PROD

const httpLink = new HttpLink({
  uri: isProduction ? 'https://api-lb.eclass.com/graphql' : '/graphql',
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      origin: 'https://campus.eclass.com',
    },
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

export default client
