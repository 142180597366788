const setFavicon = (favicon: string) => {
  const link = document.querySelector("link[rel='icon']") as HTMLLinkElement
  // const oldLink = document.getElementById("favicon");
  if (link) {
    link.href = favicon
  } else {
    const newLink = document.createElement('link')
    newLink.rel = 'shortcut icon'
    newLink.type = 'image/x-icon'
    newLink.href = favicon
    document.head.appendChild(link)
  }
}

export const updateFavicon = (faviconUrl: string) => {
  const savedFavicon = localStorage.getItem('linkfavicon')
  if (savedFavicon) {
    setFavicon(savedFavicon)
  } else {
    // Obtener nuevo favicon y actualizar
    if (faviconUrl) {
      setFavicon(faviconUrl)
      localStorage.setItem('linkfavicon', faviconUrl)
    }
  }
}

export const getFavicon = () => {
  const favicon = localStorage.getItem('linkfavicon') ?? ''
  const link: HTMLLinkElement =
    document.querySelector("link[rel='icon']") || document.createElement('link')
  const oldLink = document.getElementById('favicon')
  link.id = 'favicon'
  link.rel = 'shortcut icon'
  link.type = 'image/x-icon'
  link.href = favicon
  if (oldLink) {
    document.head.removeChild(oldLink)
  }
  document.head.appendChild(link)
}
