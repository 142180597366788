import { IconWarning, IconInfo, IconSuccess } from '../../assets'
import { Alert } from '@chakra-ui/react'

interface AlertWarningProps {
  detail: string
  status?: 'warning' | 'info' | 'success' | 'error' | 'loading' | undefined
}

export const AlertWarning = ({ detail = '', status = 'warning' }: AlertWarningProps) => {
  const getTypeIcon = (type) => {
    switch (type) {
      case 'warning':
        return <IconWarning />
      case 'info':
        return <IconInfo />
      case 'success':
        return <IconSuccess />
      default:
        return <IconWarning />
    }
  }

  const getColorBg = (type) => {
    switch (type) {
      case 'warning':
        return '#FDEED6'
      case 'info':
        return 'var(--E0EEFA, #E0EEFA)'
      case 'success':
        return 'var(--E9F6EC, #E9F6EC)'
      default:
        return '#FDEED6'
    }
  }
  return (
    <Alert status={status} gap={3} bgColor={getColorBg(status)} sx={{ borderRadius: '8px' }}>
      {getTypeIcon(status)}
      {detail}
    </Alert>
  )
}
