import { Icon } from '@chakra-ui/react'

export const IconWarning = () => {
  return (
    <Icon viewBox="0 0 52 45" focusable="false" height="45px" width="52px">
      <title>icon-iconWarning</title>
      <svg fill="none" height="45px">
        <path
          d="M21.642 44.282C33.5945 44.282 43.284 34.5925 43.284 22.64C43.284 10.6874 33.5945 0.997986 21.642 0.997986C9.68945 0.997986 0 10.6874 0 22.64C0 34.5925 9.68945 44.282 21.642 44.282Z"
          fill="white"
        ></path>
        <path
          d="M18.5 24.235C19.3284 24.235 20 23.5634 20 22.735C20 21.9066 19.3284 21.235 18.5 21.235C17.6716 21.235 17 21.9066 17 22.735C17 23.5634 17.6716 24.235 18.5 24.235Z"
          fill="#FFBC27"
        ></path>
        <path
          d="M23.5 24.235C24.3284 24.235 25 23.5634 25 22.735C25 21.9066 24.3284 21.235 23.5 21.235C22.6716 21.235 22 21.9066 22 22.735C22 23.5634 22.6716 24.235 23.5 24.235Z"
          fill="#FFBC27"
        ></path>
        <path
          d="M28.5 24.235C29.3284 24.235 30 23.5634 30 22.735C30 21.9066 29.3284 21.235 28.5 21.235C27.6716 21.235 27 21.9066 27 22.735C27 23.5634 27.6716 24.235 28.5 24.235Z"
          fill="#FFBC27"
        ></path>
        <path
          d="M43.678 14.018C40.657 8.76899 34.991 5.23499 28.5 5.23499C18.835 5.23499 11 13.07 11 22.735C11 32.4 18.835 40.235 28.5 40.235C34.608 40.235 39.984 37.106 43.115 32.364"
          stroke="#808080"
          strokeWidth="2"
          strokeLinecap="round"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.782 14.053C50.1627 14.053 51.282 15.1723 51.282 16.553V21.12C51.282 22.5007 50.1627 23.62 48.782 23.62C47.4013 23.62 46.282 22.5007 46.282 21.12V16.553C46.282 15.1723 47.4013 14.053 48.782 14.053ZM47.0143 25.7852C47.9906 24.8089 49.5735 24.8089 50.5498 25.7852L50.5958 25.8312C51.5721 26.8076 51.572 28.3905 50.5957 29.3668C49.6194 30.3431 48.0365 30.343 47.0602 29.3667L47.0142 29.3207C46.0379 28.3444 46.0379 26.7615 47.0143 25.7852Z"
          fill="#FFBC27"
        ></path>
      </svg>
    </Icon>
  )
}
