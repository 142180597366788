import { Icon } from '@chakra-ui/react'

/*
 * LoadingAnimation component
 * Este componente representa un spinner de carga
 */

export const LoadingAnimation = ({ size = 60 }) => {
  return (
    <Icon
      id="prefix__e4LDurnKGoF1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width={size}
      height={size}
    >
      <style
        dangerouslySetInnerHTML={{
          __html:
            '@keyframes e4LDurnKGoF2_to__to{0%{transform:translate(71.329326px,45.664663px);animation-timing-function:cubic-bezier(.645,.045,.355,1)}75%{transform:translate(71.329326px,58.975606px);animation-timing-function:cubic-bezier(.645,.045,.355,1)}to{transform:translate(71.329326px,45.664663px)}}@keyframes e4LDurnKGoF3_to__to{0%{transform:translate(54.984975px,45.664663px);animation-timing-function:cubic-bezier(.645,.045,.355,1)}58.333333%{transform:translate(54.984975px,58.975606px);animation-timing-function:cubic-bezier(.645,.045,.355,1)}to{transform:translate(54.984975px,45.664663px)}}@keyframes e4LDurnKGoF4_to__to{0%{transform:translate(36.864061px,45.664663px);animation-timing-function:cubic-bezier(.645,.045,.355,1)}41.666667%{transform:translate(36.466324px,57.798569px);animation-timing-function:cubic-bezier(.645,.045,.355,1)}to{transform:translate(36.864061px,45.664663px)}}@keyframes e4LDurnKGoF5_to__to{0%{transform:translate(20px,45.664663px);animation-timing-function:cubic-bezier(.645,.045,.355,1)}25%{transform:translate(20px,58.975606px);animation-timing-function:cubic-bezier(.645,.045,.355,1)}to{transform:translate(20px,45.664663px)}}',
        }}
      />
      <g
        transform="translate(71.33 45.665)"
        style={{
          animation: 'e4LDurnKGoF2_to__to 1200ms linear infinite normal forwards',
        }}
      >
        <circle
          id="prefix__e4LDurnKGoF2"
          r={4.335}
          transform="translate(-15.665 -15.665)"
          fill="#FF554D"
          stroke="none"
          strokeWidth={1}
        />
      </g>
      <g
        transform="translate(54.985 45.665)"
        style={{
          animation: 'e4LDurnKGoF3_to__to 1200ms linear infinite normal forwards',
        }}
      >
        <circle
          id="prefix__e4LDurnKGoF3"
          r={4.335}
          transform="translate(-15.665 -15.665)"
          fill="#1EBDAF"
          stroke="none"
          strokeWidth={1}
        />
      </g>
      <g
        transform="translate(36.864 45.665)"
        style={{
          animation: 'e4LDurnKGoF4_to__to 1200ms linear infinite normal forwards',
        }}
      >
        <circle
          id="prefix__e4LDurnKGoF4"
          r={4.335}
          transform="translate(-15.665 -15.665)"
          fill="#FFBC27"
          stroke="none"
          strokeWidth={1}
        />
      </g>
      <g
        transform="translate(20 45.665)"
        style={{
          animation: 'e4LDurnKGoF5_to__to 1200ms linear infinite normal forwards',
        }}
      >
        <circle
          id="prefix__e4LDurnKGoF5"
          r={4.335}
          transform="translate(-15.665 -15.665)"
          fill="#0189FF"
          stroke="none"
          strokeWidth={1}
        />
      </g>
    </Icon>
  )
}
