import { gql } from '@apollo/client'

export const CHANGE_PSW_MUTATION = gql`
  mutation UserChangePasswd($UserChangePasswdInput: UserChangePasswdInput!) {
    UserChangePasswd(input: $UserChangePasswdInput) {
      status {
        success
        detail
      }
    }
  }
`
